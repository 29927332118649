import './styles/Home.css';

import TopNavBar from '../componenets/TopNavBar';
import BackgroundCarousel from '../componenets/BackgroundCarousel';
import InterviewCard from '../componenets/InterviewCard';
import { navBarKeys } from '../helpers/navBarConsts';
import { Card, Row, Col, Container, Image } from 'react-bootstrap';

import TeamPhoto1 from '../assets/images/team photo.jpg';
import TeamPhoto2 from '../assets/images/team photo 2.jpg';
import AspirePhoto1 from '../assets/images/aspire pic 1.jpg';
import AspirePhoto2 from '../assets/images/aspire pic 2.jpg';
import InfraAndSecLogo from '../assets/logos/infra and sec logo.png';

import RajImage from '../assets/images/raj image.jpg'
import TusharImage from '../assets/images/tushar dp.jpg'
import SudhirImage from '../assets/images/sudhir dp.jpg'
import JavedImage from '../assets/images/javed dp.jpg'
import ArvindImage from '../assets/images/arvind dp.jpg'
import JaitoonImage from '../assets/images/jaitoon dp.jpg'
import SwatiImage from '../assets/images/swati dp.jpg'
import Footer from '../componenets/Footer';

import GlobalState from '../helpers/GlobalState';

const Home = () => {
    let carouselImages = [TeamPhoto1, TeamPhoto2, AspirePhoto1, AspirePhoto2];

    const gs = GlobalState.getInstance();
    gs.setState('window-title', 'Infra and Security');

    return (
        <>
            <TopNavBar selected={navBarKeys.home} />
            <Row>
                <BackgroundCarousel images={carouselImages} height={60}>
                    <Row>
                        <Col xs={3} className='d-flex justify-content-center align-items-center'>
                            <Image src={InfraAndSecLogo} className='teamLogoImage' rounded />
                        </Col>
                        <Col className='d-flex flex-column justify-content-center align-items-left'>
                            <h1>Infra and Security</h1>
                            <p>As the Infrastructure and Security team, we are the foundation upon which our customer's digital operations are built and safeguarded. Our mission is to ensure robust and scalable infrastructure that supports seamless business continuity, while implementing cutting-edge security measures to protect against evolving threats. Our commitment to excellence in these domains is unwavering, as we understand that the integrity and reliability of systems are critical to the success and trustworthiness of any organization.</p>
                        </Col>
                    </Row>
                </BackgroundCarousel>
            </Row>
            <Container>
                <Row style={{ margin: '2em 0em' }}>
                    <Card>
                        <Row>
                            <Col xs={3}>
                                <Card.Img src={RajImage} />
                            </Col>
                            <Col style={{ marginTop: '1em' }}>
                                <Card.Title>
                                    <h2>Meet Our Leader</h2>
                                </Card.Title>
                                <Card.Body>
                                    <p>"Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus, at? Sit dicta in magnam ullam et, laboriosam aliquam cumque veritatis velit officia omnis obcaecati magni hic cum illum, minus doloremque."</p>
                                    <p><small className="text-muted">- Rajesh Vudayagiri, CONSULTING PRACTICE LDR (Infra and Security)</small></p>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </Row>
                <Row>
                    <Col className='d-flex flex-row'>
                        <div className='p-3 d-flex flex-column justify-content-center'>
                            <h2>Meet</h2>
                            <h2>Our</h2>
                            <h2>Managers</h2>
                        </div>
                        <InterviewCard name='Javed Saleem' dp={JavedImage} position='CONSULTANT MGR' />
                        <InterviewCard name='Arvind Chandramohan' dp={ArvindImage} position='CONSULTANT MGR' />
                        <InterviewCard name='Sudhir Sadhoo' dp={SudhirImage} position='CONSULTANT MGR' />
                        <InterviewCard name='Tushar Sinha' dp={TusharImage} position='CONSULTANT MGR' />
                        <InterviewCard name='Jaitoon Nisha' dp={JaitoonImage} position='CONSULTANT MGR' />
                        <InterviewCard name='Swati Das' dp={SwatiImage} position='CONSULTANT MGR' />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default Home;