import { Button, Card } from "react-bootstrap";
import NewsLetterOverlay from "./NewsLetterOverlay";
import { useState } from "react";

const NewsLetterCard = (props) => {
    const formatDate = (date) => {
        try {
            date = new Date(date)
            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
        }
        catch {
            return false
        }
    }
    console.log(props)
    const values = {
        title: props.title || 'Sample Newsletter',
        date: formatDate(props.date) || 'Date Not Available',
        content: props.content || 'This is a sample newsletter preview',
        id: props._id
    }

    
    const [show, setShow] = useState(false);
    const readMoreHandler = () => {
        setShow(true)
    }
    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>{values.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{values.date}</Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        {values.content}
                    </Card.Text>
                    <Button variant="primary" onClick={readMoreHandler}>Read More</Button>
                </Card.Body>
            </Card>
            <NewsLetterOverlay show={show} setShow={setShow} values={values}/>
        </>
    )
}

export default NewsLetterCard;