import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import Home from './pages/Home';
import Awards from './pages/Awards';
import Readiness from './pages/Readiness';
import Innovations from './pages/Innovations';
import Leaderboard from './pages/Leaderboard';
import Newsletters from './pages/Newsletters';
import Communities from './pages/Communities';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import urls from './helpers/urlsConsts';


function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={urls.home} element={<Home />} />
				<Route path={urls.awards} element={<Awards />} />
				<Route path={urls.readiness} element={<Readiness />} />
				<Route path={urls.innovations} element={<Innovations />} />
				<Route path={urls.leaderboard} element={<Leaderboard />} />
				<Route path={urls.newsletters} element={<Newsletters />} />
				<Route path={urls.communities} element={<Communities />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
