import './styles/InterviewCard.css'

import { Card } from "react-bootstrap"

const InterviewCard = (props) => {
    return (
        <>
            <Card className='interview-card-card'>
                <Card.Img className="interview-card-card-image" src={props.dp}/>
                <Card.Body>
                    <p>{props.description}</p>
                    <p><small className="text-muted">{props.name}, {props.position}</small></p>
                </Card.Body>
            </Card>
        </>
    )
}

export default InterviewCard;