import './styles/Home.css';
import TopNavBar from '../componenets/TopNavBar';
import { navBarKeys } from '../helpers/navBarConsts';
import Footer from '../componenets/Footer';
import BackgroundCarousel from '../componenets/BackgroundCarousel';
import {Row, Col, Image} from 'react-bootstrap';

import communityImage from '../assets/logos/Community.png';
import communityBackgroundImage from '../assets/images/community.jpg';

import GlobalState from '../helpers/GlobalState';

const Communities = () => {
    const gs = GlobalState.getInstance();
    gs.setState('window-title', 'Communities');

    return (
        <>
            <TopNavBar selected={navBarKeys.communities} />
            <Row>
                <BackgroundCarousel images={[communityBackgroundImage]} height={60}>
                    <Row>
                        <Col xs={3} className='d-flex justify-content-center align-items-center'>
                            <Image src={communityImage} className='teamLogoImage' rounded />
                        </Col>
                        <Col className='d-flex flex-column justify-content-center align-items-left'>
                            <h1>Communities</h1>
                            <p>Awards play a crucial role in a company's ecosystem by enhancing brand image, boosting stakeholder confidence, and stimulating internal morale. They serve as a testament to a company's reliability and reputation, which can increase customer trust and attract top talent.</p>
                        </Col>
                    </Row>
                </BackgroundCarousel>
            </Row>
            <Footer />
        </>
    )
}

export default Communities;