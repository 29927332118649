import GlobalState from './GlobalState';
import {setTitle} from './windowUtils';

const InitApplication = () => {
    registerWindowTitleUpdater();
}

const registerWindowTitleUpdater = () => {
    const gs = GlobalState.getInstance()
    gs.addState('window-title', 'React App');
    gs.addStateListener('window-title', setTitle);
}

export default InitApplication;