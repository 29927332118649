import {api} from './routes';

export const getAll = () => {
    return new Promise((resolve, reject) => {
        fetch(api.newsletters.getAll)
        .then(res => {
            return res.json()
        })
        .then(data => {
            if(data.status === 'successful') {
                resolve(data.data);
            } else {
                reject(data.message);
            }
        })
        .catch(err => reject(err));
    })
}

export const get = (id) => {
    return new Promise((resolve, reject) => {
        fetch(api.newsletters.get + id)
        .then(res => res.json())
        .then(data => {
            if(data.status === 'successful') {
                resolve(data.data);
            } else {
                reject(data.message);
            }
        })
        .catch(err => reject(err));
    })
}