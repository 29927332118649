import './styles/Innovations.css';
import TopNavBar from '../componenets/TopNavBar';
import { navBarKeys } from '../helpers/navBarConsts';
import Footer from '../componenets/Footer';
import BackgroundCarousel from '../componenets/BackgroundCarousel';
import { Container, Row, Col, Image } from 'react-bootstrap';
import InnovationCard from '../componenets/InnovationCard';

import innovationImage from '../assets/logos/innovation.png';
import innovationBackgroundImage from '../assets/images/innovation.jpg';

import GlobalState from '../helpers/GlobalState';
import { useEffect, useState } from 'react';

const Innovations = () => {
    const gs = GlobalState.getInstance();
    gs.setState('window-title', 'Innovations');

    const innovationData = [
        {
            'title': 'AWS RDS Databases to Azure Migration',
            'owner': 'Anil Kumar Reddy Kakularam',
            'community': 'Azure Migration',
            'description': 'Perform POCs for AWS based RDS migrations to Azure using the existing SQL migration SKUs in CTS offering and GDC supported SKUs',
            'purpose': 'We identified RDS databases and scenarios for projects like LSEG as part of the AWS to Azure migration scope. During pre-sales (BP, Rio-Tinto), We also observed that these SKUs will help add more workloads for our factory migration.',
            'impact': 'we have developed additional capabilities within SQL Migration Factory SKUs to support AWS RDS to Azure Migration.'
        },
        {
            'title': 'Azure Migrate dependency analysis',
            'owner': 'Shubham Agnihotri/Suresh Selvamoorthy',
            'community': 'Azure Migration',
            'description': 'Automation to filter out unwanted data from Azure Migrate dependency analysis, so that we can group the servers and process the firewall rules more efficiently.',
            'purpose': 'Consulting working on Migration delivery were putting lots of manual efforts on mapping the rules and groupig the application resulting in Manual errors',
            'impact': 'Reduce Manual Efforts by 80%​'
        },
        {
            'title': 'AVS Migration',
            'owner': 'Asish Srivastava/Himanshu Sharma',
            'community': 'Azure Migration',
            'description': 'As a migration factory team member, I want to have a step-by-step user guide/SOPs for on-premises VMware to Azure VMware Solution (AVS) migration, so that I can follow the standard process and deliver high-quality migration with out any defects issues.',
            'purpose': 'With Inclusion and high Demand of AVS Migration across multiple customer, Delivery consultant were missing the standard documentation to perform the delivery',
            'impact': 'This SOP is expected to optimize the migration consultant effort by 30%. '
        },
        {
            'title': 'Containers Discovery',
            'owner': 'Shubham Agnihotri',
            'community': 'Azure Migration',
            'description': 'Enhancing CTS Offering for Container Migrations , Development of an AI Enabled Automated Container Discovery Framework for seamless configuration and workload management across all Kubernetes clusters, eliminating manual processes for migration to Azure',
            'purpose': 'Container Discovery is intended for scenarios where we need to migrate from a container-based environment on-premises or other cloud provider (Managed/Unmanaged Kubernetes) or on other clouds to Azure. To start with these kinds of scenarios, the discovery process is the key to understand the customer’s current state of container applications infrastructure.',
            'impact': 'Efficiency: Automatic configuration and workload management streamline designing , reducing the time and effort required for manual processes. Optimization: The use of AI to provide recommendations and guidance aligns with tailored practices, further enhancing operational efficiency. Innovation: This approach represents a forward-thinking application of technology, potentially setting a new standard for Kubernetes cluster management. Velocity: This can help in achieving stronger & required Time to Market for the customer. This solution will enhance the current container migration process and optimize the consultant efforts by 50% currently targetted for Kubenetes workload'
        },
        {
            'title': 'DAST scanning onboarding - OWASP ZAP scanner',
            'owner': 'Chirag Desai',
            'community': 'Sec DevOps',
            'description': 'As an Architect, I want to build pipeline for DAST scanning with OWASP ZAP tool integrated. So that I can integrate OWASP ZAP, which is an open-source proxy into azure pipelines. This would ensure that the deployed applications are scanned periodically without any dependency on InfoSec consultant.',
            'purpose': 'Dynamic Application Security Testing is requirement of Securre By Default Process and currently there are no pipeline/task present that can perform the task and it. By building this pipeline that can authenticate itself and run the scan on target application, Enabling us to find vulnerabilities in checked-in code on each build/release.',
            'impact': 'The requirement of DAST tooling can be scaled out and implemented in all projects - even where InfoSec consultants aren\'t onboarded on the project, for example - self-serve projects. Enabling us to find vulnerabilities in checked-in code on each build/release.'
        },
        {
            'title': 'ALZ Zero Touch Deployment',
            'owner': 'Kavish Shivhare/Aashrith Bulusu',
            'community': 'Infra as a Code',
            'description': 'Aims to simlify the deployment of landing zone',
            'purpose': 'Zero touch Azure landing zone deployment, to reduce time and effort required for a typical AZL engagement. Initial version of this is released and being used in GOK. ',
            'impact': 'This will help reduce the time and efforts involved in building a typical  Azure landing zone.'
        },
        {
            'title': 'Deploy Sentinel Content - Sentinel repositories feature',
            'owner': 'Lishweth KM',
            'community': 'Security',
            'description': 'Reduce human errors and improve consistency across environemnts. Also accelerate the adoption of new\\updated content',
            'purpose': 'Sentinel repositories is useful in complex sentinel engagements where there are multiple workspaces. This allows automation and eases deployment at scale.',
            'impact': 'SCBX had ~13 workspaces and using this feature we were able to deploy content which would have taken weeks to deploy in a day. '
        },
        {
            'title': 'GitOps for AKS',
            'owner': 'Ramana, Pavan',
            'community': 'Container',
            'description': 'GitOps provides standard set of practices across all clusters in the estate. As a IaC developer, I would like to create an IP that could be implemented for customer to ensure considerations such as reliability, security, cost optimization, and operational concerns of the customer are addressed.',
            'purpose': 'There is no straightforward playbook that can be used in customer deliveries when deploying AKS clusters, various integration points, application deployments with minimum human intervention. So this IP which has a playbook will help stream line the delivery of any project that has AKS cluster estate.',
            'impact': 'Embracing GitOps for AKS makes it the cornerstone of our security strategy for AKS. By automating the installation of security tools through GitOps, we can ensure that every AKS cluster deployment establishes a robust and consistent security baseline. This transformative approach not only fortifies our infrastructure but also empowers us to proactively safeguard our applications on AKS, paving the way for a secure, resilient, and continuously evolving containerized environment.'
        }
    ]

    const [innovations, setInnovations] = useState(null);
    useEffect(() => {
        setInnovations(innovationData.map((innovation, index) => {
            return (
                <InnovationCard key={index} title={innovation.title} owner={innovation.owner} community={innovation.community} description={innovation.description} purpose={innovation.purpose} impact={innovation.impact} />
            )
        }));

    }, []);

    return (
        <>
            <TopNavBar selected={navBarKeys.innovations} />
            <Row>
                <BackgroundCarousel images={[innovationBackgroundImage]} height={60}>
                    <Row>
                        <Col xs={3} className='d-flex justify-content-center align-items-center'>
                            <Image src={innovationImage} className='teamLogoImage' rounded />
                        </Col>
                        <Col className='d-flex flex-column justify-content-center align-items-left'>
                            <h1>Innovations</h1>
                            <p>The development of new technologies within a service-based company is crucial for maintaining competitive advantage and fostering innovation. It enables the company to improve service delivery, enhance customer experience, and streamline operations. By investing in new technologies, a service-based company can also expand its offerings, tap into new markets, and adapt to changing industry trends. Ultimately, this commitment to technological advancement can lead to increased efficiency, higher revenue, and sustained growth.</p>
                        </Col>
                    </Row>
                </BackgroundCarousel>
            </Row>
            <Container className='innovation-website-content-container'>
                <Row>
                    <Col>
                        <div className='innovation-list-div'>
                            {innovations}
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default Innovations;