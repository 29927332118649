import './styles/TopNavBar.css';

import { Container, Navbar, Nav } from "react-bootstrap";
import urls from "../helpers/urlsConsts";
import { navBarKeys } from '../helpers/navBarConsts';

import MS_Logo from '../assets/logos/Microsoft-logo_rgb_c-gray.png';
import GCD_LOGO from '../assets/logos/black full logo.png';

import { useEffect, useState } from 'react';

const TopNavBar = (props) => {
    const [active, setActive] = useState('home');

    useEffect(() => {
        selectButton(props.selected);
    })

    const selectButton = (keyName) => {
        setActive(keyName)
    }

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary" bg="light" variant="light">
                <Container fluid>
                    <Navbar.Brand href={urls.home}>
                        <div className="navbar-brand-div">
                            <img src={MS_Logo} alt='Microsoft Logo'></img>
                            <img src={GCD_LOGO} alt='GDC Logo'></img>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="topnavbar-links" />
                    <Navbar.Collapse id="topnavbar-links" className="justify-content-end">
                        <Nav activeKey={active}>
                            <Nav.Link eventKey={navBarKeys.home} href={urls.home}>Home</Nav.Link>
                            <Nav.Link eventKey={navBarKeys.awards} href={urls.awards}>Awards</Nav.Link>
                            <Nav.Link eventKey={navBarKeys.readiness} href={urls.readiness}>Readiness</Nav.Link>
                            <Nav.Link eventKey={navBarKeys.innovations} href={urls.innovations}>Innovations</Nav.Link>
                            <Nav.Link eventKey={navBarKeys.leaderboard} href={urls.leaderboard}>Leaderboard</Nav.Link>
                            <Nav.Link eventKey={navBarKeys.newsletters} href={urls.newsletters}>Newsletters</Nav.Link>
                            <Nav.Link eventKey={navBarKeys.communities} href={urls.communities}>Communities</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default TopNavBar;