import { useState, useEffect } from 'react';
import { get } from '../apis/newsletters';
import './styles/NewsLetterOverlay.css';

import { Modal } from "react-bootstrap";

const NewsLetterOverlay = (props) => {
    const [newsletterUrl, setNewsletterUrl] = useState('about:blank');

    useEffect(() => {
        get(props.values.id)
        .then(url => {
            setNewsletterUrl(url)
        })
        .catch(err => {
            console.error(err)
        })
    }, [])

    const handleClose = () => props.setShow(false);
    return (
        <>
            <Modal show={props.show} onHide={handleClose} dialogClassName="my-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{props.values.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe title={props.values.title} style={{width: '85vw', height: '80vh'}} src={newsletterUrl}></iframe>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NewsLetterOverlay;