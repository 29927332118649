import './styles/BackgroundCarousel.css';

import { useEffect, useState } from "react";

import { Card } from "react-bootstrap";

const BackgroundCarousel = (props) => {
    const [headerImageUrl, setHeaderImageUrl] = useState(props.images[0]);
    const [headerImageClass, setHeaderImageClass] = useState('card-background-image-user');
    let imagePosition = 0;

    const styles = {
        card: {
            height: `${props.height || 40}vh`
        }
    }

    useEffect(() => {
        if (props.images.length > 1) {
            autoUpdateImage();
        }
    }, []);

    const autoUpdateImage = () => {
        const interval = props.interval || 5000;
        setInterval(() => {
            setHeaderImageClass('card-background-image-user zoomOutEffect');
            setTimeout(() => {
                updateImage();
                setHeaderImageClass('card-background-image-user slowZoomInEffect');
            }, interval*0.1);
        }, interval);
    }
    const updateImage = () => {
        imagePosition++;
        if (imagePosition >= props.images.length) {
            imagePosition = 0;
        }
        setHeaderImageUrl(props.images[imagePosition]);        
    }
    return (
        <>
            <Card style={styles.card}>
                <img className={headerImageClass} src={headerImageUrl} alt=""/>
                <div className='card-img-overlay text-white d-flex flex-column justify-content-center align-items-center overlay-dark'>
                    <div className='ms-5 me-5'>
                        {props.children}
                    </div>
                </div>
            </Card>
        </>
    )
}

export default BackgroundCarousel;