import { Card } from "react-bootstrap";

const InnovationCard = (props) => {
    const title = props.title || null;
    const owner = props.owner || null;
    const community = props.community || null;
    const description = props.description || null;
    const purpose = props.purpose || null;
    const impact = props.impact || null;

    return (
        <>
            <Card>
                <Card.Header>
                    <h3>{title}</h3>
                    <p><small className="text-muted">{owner===null?'':'owned by'} {owner} ({community})</small></p>
                </Card.Header>
                <Card.Body>
                    <h5>{description===null?'':'Description'}</h5>
                    <p>{description}</p>
                    <h5>{purpose===null?'':'Purpose'}</h5>
                    <p>{purpose}</p>
                    <h5>{impact===null?'':'Impact'}</h5>
                    <p>{impact}</p>
                </Card.Body>
            </Card>
        </>
    )
}

export default InnovationCard;