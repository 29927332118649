class GlobalState {
    static __instance__ = null;
    constructor() {
        this.states = {};
        this.stateListeners = {};
    }
    static getInstance() { 
        if (GlobalState.__instance__ === null) {
            GlobalState.__instance__ = new GlobalState();
        }
        return GlobalState.__instance__;
    }
    hasState(key) {
        return this.states[key] !== undefined;
    }
    addState(key, value) {
        if(this.states[key] !== undefined) {
            throw new Error(`State with key ${key} already exists`);
        }
        this.states[key] = value;
        this.stateListeners[key] = [];
        this.#triggerStateValueChange(key);
    }
    setState(key, value) {
        this.states[key] = value;
        this.#triggerStateValueChange(key);
    }
    getState(key) {
        if(this.states[key] === undefined) {
            throw new Error(`State with key ${key} does not exist`);
        }
        return this.states[key];
    }
    removeState(key) {
        if(this.states[key] === undefined) {
            throw new Error(`State with key ${key} does not exist`);
        }
        delete this.states[key];
        delete this.stateListeners[key];
    }
    addStateListener(key, listener) {
        if(this.stateListeners[key] === undefined) {
            throw new Error(`State with key ${key} does not exist`);
        }
        this.stateListeners[key].push(listener);
    }
    removeStateListener(key, listener) {
        if(this.stateListeners[key] === undefined) {
            throw new Error(`State with key ${key} does not exist`);
        }
        this.stateListeners[key] = this.stateListeners[key].filter(l => l !== listener);
    }
    #triggerStateValueChange(key) {
        if(this.stateListeners[key] !== undefined) {
            this.stateListeners[key].forEach(listener => listener(this.states[key]));
        }
    }
}

export default GlobalState;