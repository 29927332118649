import './styles/Awards.css';
import TopNavBar from '../componenets/TopNavBar';
import { navBarKeys } from '../helpers/navBarConsts';
import Footer from '../componenets/Footer';
import BackgroundCarousel from '../componenets/BackgroundCarousel';
import InterviewCard from '../componenets/InterviewCard';
import { Row, Col, Image, Container, Card } from 'react-bootstrap';

import awardImage from '../assets/logos/awards.png'
import awardBackgroundImage from '../assets/images/awards.jpg'

import GlobalState from '../helpers/GlobalState';

import TusharImage from '../assets/images/tushar dp.jpg';
import UserImage from '../assets/images/user.png';

const Awards = () => {
    const gs = GlobalState.getInstance();
    gs.setState('window-title', 'Awards');

    return (
        <>
            <TopNavBar selected={navBarKeys.awards} />
            <Row>
                <BackgroundCarousel images={[awardBackgroundImage]} height={60}>
                    <Row>
                        <Col xs={3} className='d-flex justify-content-center align-items-center'>
                            <Image src={awardImage} className='teamLogoImage' rounded />
                        </Col>
                        <Col className='d-flex flex-column justify-content-center align-items-left'>
                            <h1>Awards</h1>
                            <p>Awards play a crucial role in a company's ecosystem by enhancing brand image, boosting stakeholder confidence, and stimulating internal morale. They serve as a testament to a company's reliability and reputation, which can increase customer trust and attract top talent.</p>
                        </Col>
                    </Row>
                </BackgroundCarousel>
            </Row>
            <Container className='awards-website-content-container'>
                <Row>
                    <Col>
                        <h2>H1 Rise Awards</h2>
                        <p>Our CoE received the following awards under Rise awards:</p>
                        <ul>
                            <li>Individual Awards</li>
                            <li>Manager Excellence Awards</li>
                            <li>Team Awards</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className='mt-2 mb-2'>
                            <Card.Header>
                                <h3>Individual Awards</h3>
                            </Card.Header>
                            <Card.Body className='d-flex flex-row'>
                                <InterviewCard dp={UserImage} name='Akash Dholaria' position='Consultant' />
                                <InterviewCard dp={UserImage} name='Karni Gupta' position='Consultant' />
                                <InterviewCard dp={UserImage} name='Sunidhi' position='Consultant' />
                            </Card.Body>
                        </Card>
                        <Card className='mt-2 mb-2'>
                            <Card.Header>
                                <h3>Manager Excellence Awards</h3>
                            </Card.Header>
                            <Card.Body className='d-flex flex-row'>
                                <InterviewCard dp={TusharImage} name='Tushar Sinha' position='Manager' />
                            </Card.Body>
                        </Card>
                        <Card className='mt-2 mb-2'>
                            <Card.Header>
                                <h3>Team Awards</h3>
                            </Card.Header>
                            <Card.Body>
                                <div>
                                    <h5 className='ms-2'>CSV Health</h5>
                                    <div className='d-flex flex-row'>
                                        <InterviewCard dp={UserImage} name='Anil Kumar Reddy Kakularam' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Devi Priya' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Manoj Jacob' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Nupur Mitra' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Prabha Giduthuri' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Sindhu Marapareddy' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Venkat Pathuri' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Vijay Negi' position='Consultant' />\
                                    </div>
                                </div>
                                <div>
                                    <h5 className='ms-2'>Infosys</h5>
                                    <div className='d-flex flex-row'>
                                        <InterviewCard dp={UserImage} name='Gaurav Jain' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Mohanan Ambika Rohit Nair' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Priyanka Agarwal' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Yash Garg' position='Consultant' />
                                    </div>
                                </div>
                                <div>
                                    <h5 className='ms-2'>EMEA - European Medicines Agency</h5>
                                    <div className='d-flex flex-row'>
                                        <InterviewCard dp={UserImage} name='Satyandra Vishwakarma' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Uzma Khan' position='Consultant' />
                                    </div>
                                </div>
                                <div>
                                    <h5 className='ms-2'>Mercedes Benz AG</h5>
                                    <div className='d-flex flex-row'>
                                        <InterviewCard dp={UserImage} name='Milind Sardana' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Mukund Ji' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Sonali Wadhwa' position='Consultant' />
                                    </div>
                                </div>
                                <div>
                                    <h5 className='ms-2'>Truist Insurance Holding</h5>
                                    <div className='d-flex flex-row'>
                                        <InterviewCard dp={UserImage} name='Ranjana Sharma' position='Consultant' />
                                    </div>
                                </div>
                                <div>
                                    <h5 className='ms-2'>NSW Health - EDWARD</h5>
                                    <div className='d-flex flex-row'>
                                        <InterviewCard dp={UserImage} name='Jyoti Kumari' position='Consultant' />
                                    </div>
                                </div>
                                <div>
                                    <h5 className='ms-2'>United Health Group: Multi-Tenant Collaboration and Management</h5>
                                    <div className='d-flex flex-row'>
                                        <InterviewCard dp={UserImage} name='Ehab Isaac,  Rohit Agrawal' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Amol Joglekar' position='Consultant' />
                                    </div>
                                </div>
                                <div>
                                    <h5 className='ms-2'>Synopsis INC</h5>
                                    <div className='d-flex flex-row'>
                                        <InterviewCard dp={UserImage} name='Madhuri Reddi' position='Consultant' />
                                        <InterviewCard dp={UserImage} name='Sundar Balaji Anantharamakrishnan' position='Consultant' />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default Awards;