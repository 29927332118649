import './styles/Newsletters.css';
import TopNavBar from '../componenets/TopNavBar';
import { navBarKeys } from '../helpers/navBarConsts';
import Footer from '../componenets/Footer';
import BackgroundCarousel from '../componenets/BackgroundCarousel';
import InterviewCard from '../componenets/InterviewCard';
import { Row, Col, Image, Container } from 'react-bootstrap';
import { getAll } from '../apis/newsletters'

import newsletterImage from '../assets/logos/newsletter.png';
import NewsLetterCard from '../componenets/NewsLetterCard';
import newsletterHeaderImage from '../assets/images/Newsletter.jpg'

import DeveshImage from '../assets/images/devesh birla dp.jpg';
import NiralImage from '../assets/images/niral veram dp.jpg';
import AkaSaiImage from '../assets/images/aka sai dp.jpg';
import BhavaysreeImage from '../assets/images/bhavaysree damarla dp.jpg';
import SoumyaImage from '../assets/images/soumya shet dp.jpg';
import KarniImage from '../assets/images/karni gupta dp.jpg';
import PrabhaImage from '../assets/images/prabha giduthuri dp.jpg';
import AvitijImage from '../assets/images/avijit prasad.jpg';

import GlobalState from '../helpers/GlobalState';
import { useState, useEffect } from 'react';

const Newsletters = () => {
    const [newsletters, setNewsletters] = useState([]);

    const gs = GlobalState.getInstance();
    gs.setState('window-title', 'Newsletters');

    useEffect(() => {
        getAll()
            .then(data => {
                setNewsletters(data);
            })
            .catch(err => {
                console.error(err);
            })
    }, []);

    return (
        <>
            <TopNavBar selected={navBarKeys.newsletters} />
            <Row>
                <BackgroundCarousel images={[newsletterHeaderImage]} height={60}>
                    <Row>
                        <Col xs={3} className='d-flex justify-content-center align-items-center'>
                            <Image src={newsletterImage} className='teamLogoImage' rounded />
                        </Col>
                        <Col className='d-flex flex-column justify-content-center align-items-left'>
                            <h1>Newsletters</h1>
                            <p>Newsletters play a crucial role in companies by fostering communication, engagement, and brand awareness. They serve as a regular touchpoint with clients, keeping them informed about new offerings, company news, and industry insights. This consistent flow of information can help build trust and loyalty, encouraging repeat business. Moreover, newsletters can also be a platform for gathering feedback, allowing companies to adapt and improve their services in alignment with customer needs and preferences. Ultimately, they are a valuable tool for maintaining a dynamic and client-focused business approach.</p>
                        </Col>
                    </Row>
                </BackgroundCarousel>
            </Row>
            <Container className='newsletter-website-content'>
                <Row className='newsletter-section'>
                    <h2>Our Newsletter Team</h2>
                    <Col className='d-flex flex-row flex-wrap'>
                        <InterviewCard name='Devesh Biral' dp={DeveshImage} position='CONSULTANT' />
                        <InterviewCard name='Niral Verma' dp={NiralImage} position='CONSULTANT' />
                        <InterviewCard name='Aka Sai Lalith Kumar' dp={AkaSaiImage} position='CONSULTANT' />
                        <InterviewCard name='Bhavaysree Damarla' dp={BhavaysreeImage} position='CONSULTANT' />
                        <InterviewCard name='Soumya Shet' dp={SoumyaImage} position='CONSULTANT' />
                        <InterviewCard name='Karni Gupta' dp={KarniImage} position='CONSULTANT' />
                        <InterviewCard name='Prabha Giduthuri' dp={PrabhaImage} position='CONSULTANT' />
                        <InterviewCard name='Avijit Prasad' dp={AvitijImage} position='CONSULTANT' />
                    </Col>
                </Row>
                <Row className='newsletter-section'>
                    <Col>
                        <h2>Our Newsletters</h2>
                        <div className='newsletter-card-list'>
                            {newsletters.map(nl => {
                                return <NewsLetterCard key={nl._id} _id={nl._id} title={nl.title} date={nl.date} />
                            })}
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default Newsletters;