import './styles/Footer.css';

import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
    return (
        <footer>
            <Container fluid>
                <Row>
                    <Col className='d-flex flex-column align-items-center'>
                        <div>
                            <h4>Careers</h4>
                            <ul>
                                <li>
                                    <a href='https://microsoft.sharepoint.com/teams/IndustrySolutions/SitePages/IS-Career-Paths.aspx' target='_blank' rel="noreferrer">ISD Careers</a>
                                </li>
                                <li>
                                    <a href='https://careers.microsoft.com/us/en' target='_blank' rel="noreferrer">Microsoft Careers</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col className='d-flex flex-column align-items-center'>
                        <div>
                            <h4>Company Resources</h4>
                            <ul>
                                <li>
                                    <a href='https://microsoft.sharepoint.com/sites/library' target='_blank' rel="noreferrer">MS Library</a>
                                </li>
                                <li>
                                    <a href='https://microsoft.sharepoint.com/teams/IndustrySolutions/SitePages/IS-Give-2023.aspx' target='_blank' rel="noreferrer">MS Give</a>
                                </li>
                                <li>
                                    <a href='https://microsoft.sharepoint.com/sites/FindYourCommunity' target='_blank' rel="noreferrer">Find your Community</a>
                                </li>
                                <li>
                                    <a href='https://microsoftapc.sharepoint.com/teams/GlobalDelivery/SitePages/GDC-CSR.aspx' target='_blank' rel="noreferrer">GDC CSR</a>
                                </li>
                                <li>
                                    <a href='https://microsoftapc.sharepoint.com/teams/GlobalDelivery/Social%20Media%20Guidelines/Forms/AllItems.aspx?id=%2Fteams%2FGlobalDelivery%2FSocial%20Media%20Guidelines%2FBest%20Practice%5FMS%20Social%20Media%20Guidelines%2Epdf&parent=%2Fteams%2FGlobalDelivery%2FSocial%20Media%20Guidelines' target='_blank' rel="noreferrer">Social Media Guidelines</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col className='d-flex flex-column align-items-center'>
                        <div>
                            <h4>Support Services</h4>
                            <ul>
                                <li>
                                    <a href='https://aka.ms/reportitnow' target='_blank' rel="noreferrer">Report It Now</a>
                                </li>
                                <li>
                                    <a href='https://microsoft.sharepoint.com/sites/CELAWeb-BusinessConduct/SitePages/workplace-issues-wit.aspx' target='_blank' rel="noreferrer">Workplace Concern</a>
                                </li>
                                <li>
                                    <a href='https://aka.ms/TechWeb' target='_blank' rel="noreferrer">Self Support Resources</a>
                                </li>
                                <li>
                                    <a href='https://microsoft.service-now.com/sp?id=call' target='_blank' rel="noreferrer">Contact Support</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col className='d-flex flex-column align-items-center'>
                        <div>
                            <h4>News Sources</h4>
                            <ul>
                                <li>
                                    <a href='https://news.microsoft.com/source/' target='_blank' rel="noreferrer">Microsoft News Center</a>
                                </li>
                                <li>
                                    <a href='https://blogs.microsoft.com/' target='_blank' rel="noreferrer">Microsoft Blog</a>
                                </li>
                                <li>
                                    <a href='https://aka.ms/mslibrary/news' target='_blank' rel="noreferrer">MS Library News Hub</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ul className='footer-lastline'>
                            <li>
                                © 2024 Microsoft
                            </li>
                            <li>
                                <a href='https://go.microsoft.com/fwlink/?linkid=2196228' target='_blank' rel="noreferrer">Trademark</a>
                            </li>
                            <li>
                                <a href='https://go.microsoft.com/fwlink/?LinkID=206977' target='_blank' rel="noreferrer">Terms of use</a>
                            </li>
                            <li>
                                <a href='https://go.microsoft.com/fwlink/?LinkId=521839' target='_blank' rel="noreferrer">Privacy</a>
                            </li>
                            <li>
                                <a href='https://support.microsoft.com/contactus' target='_blank' rel="noreferrer">Contact Microsoft</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;